const SHOW_POPUP_TIME_IN_MILLISECONDS = 5000
const POPUP_CSS_TRANSITION_DURATION_IN_MILLISECONDS = 1000
const SHOW_BUTTON_ICON_STATUS_TIME_IN_MILLISECONDS = 1000

/**
 * Add products to cart via ajax
 * @param DOMNode button "Buy" button
 * @param string url "addToCart" Magento url
 */
const addToCart = (button, url, productQty, productId) => {
  if (url.indexOf('checkout/cart') === -1) {
    window.location = url
    return
  }

  url += 'isAjax/1'
  url = url.replace('checkout/cart', 'ajax/index')

  const topCart = document.getElementById('top-cart')
  const buttonIcon = button.querySelector('i')
  const buttonLabel = button.querySelector('span')

  button.disabled = true

  buttonIcon.classList.remove('fa-shopping-cart')
  buttonIcon.classList.add('fa-spinner', 'fa-spin', 'fa-pulse')
  buttonLabel.style.display = 'none'

  jQuery.ajax({
    url,
    data: {
      id: productId,
      qty: productQty
    },
    dataType: 'json',
    success (data) {
      topCart.classList.add('fixed')
      buttonIcon.classList.remove('fa-spinner', 'fa-spin', 'fa-pulse')

      if (data.status === 'ERROR') {
        updateButtonIconStatus('fa-times', button)

        const topCart = document.getElementById('top-cart')
        const originalCartContent = topCart.innerHTML
        topCart.innerHTML = data.message

        setTimeout(() => {
          topCart.innerHTML = originalCartContent
        }, SHOW_POPUP_TIME_IN_MILLISECONDS + POPUP_CSS_TRANSITION_DURATION_IN_MILLISECONDS)
      } else {
        updateButtonIconStatus('fa-check', button)

        document.getElementById('cart').innerHTML = data.sidebar
      }

      showCartPopup()
    }
  })
}

/**
 * Temporarily show the cart as popup (from top)
 */
const showCartPopup = () => {
  const topCart = document.getElementById('top-cart')
  topCart.classList.add('fixed')

  setTimeout(() => {
    topCart.classList.add('show')
  }, 100)

  setTimeout(() => {
    topCart.classList.remove('show')

    setTimeout(() => {
      topCart.classList.remove('fixed')
    }, POPUP_CSS_TRANSITION_DURATION_IN_MILLISECONDS)
  }, SHOW_POPUP_TIME_IN_MILLISECONDS)
}

/**
 * Change button icons to indicate status
 */
const updateButtonIconStatus = (icon, button) => {
  const buttonIcon = button.querySelector('i')
  const buttonLabel = button.querySelector('span')

  buttonIcon.className = ''
  buttonIcon.classList.add('fa', icon)

  setTimeout(() => {
    buttonIcon.classList.add('fa-shopping-cart')
    buttonLabel.style.display = 'inline-block'
    button.disabled = false
  }, SHOW_BUTTON_ICON_STATUS_TIME_IN_MILLISECONDS)
}
